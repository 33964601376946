<style>
#partnersList_wrapper{
  width: 100% !important;
}
.marker {
  background-color: Yellow;
}
.tooltip-inner {
  max-width: 100% !important;
}
</style>
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
              <b-overlay :show="showLoader" no-wrap></b-overlay>
              <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3 text-center">
                    <div class="card">
                        <div class="card-body table-responsive table-head pt-2">
                            <div class="row">
                                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                                    <h1 class="m-0 text-dark pt-2 text-left px-0">Member Dashboard</h1>
                                </div>
                                <div class="col-md-4 bor-bot pr-0">
                                    <div class="text-right pb-3 pt-1">
                                        <ol class="breadcrumb float-sm-right pr-0">
                                            <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                                            <li class="breadcrumb-item"><Breadcrumbs/></li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                               <div class="col-md-12 p-0 memberdasbordsec mt-4 text-left">
                                   <div>
                                    <b-card no-body>
                                        <b-tabs pills card vertical>
                                        <b-tab title="Banner" active>
                                            <b-card-text>
                                                <div class="row text-left">
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" v-b-modal.modal-previewcenter class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                    </div>
                                                    <div class="col-md-12 mt-4 imagesec pl-0">
                                                        <div class="rectimage">
                                                            <img :src="bannerUrl" style="width: 100%;"/>
                                                        <div class="atcmntbtn" :class="bannerErrors.has('image') ? 'is-invalid' : ''">
                                                            <input type="file" @mousedown="bannerErrors.clear('image')" name="avatar" @change="fileChange(1)" ref="fileBanner" accept="image/png, image/jpeg">
                                                        </div>
                                                          <b-form-invalid-feedback :state="!bannerErrors.has('image')">
                                                            {{ bannerErrors.get("image") }}</b-form-invalid-feedback>
                                                        </div>
                                                        <p class="mt-3 mb-1 text-right notetext">Please upload 1657 X 318 image</p>
                                                    </div>
                                                    <div class="col-md-1 px-0">
                                                        <label>Heading</label>
                                                      <span v-b-tooltip.hover title="Maximum 60 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <input class="form-control" @keydown="bannerErrors.clear('title')" :class="bannerErrors.has('title') ? 'is-invalid' : ''" v-model="form.title" @input="cancelFunction('cancelButton1')">
                                                        <b-form-invalid-feedback :state="!bannerErrors.has('title')">
                                                        {{ bannerErrors.get("title") }}</b-form-invalid-feedback>
                                                    </div>
                                                    <div class="col-md-6">
                                                    </div>
                                                    <div class="col-md-1 mt-4 px-0">
                                                        <label>Text</label><span v-b-tooltip.hover title="Maximum 200 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                    </div>
                                                    <div class="col-md-11 mt-4">
                                                        <textarea rows="3" @keydown="bannerErrors.clear('content')" :class="bannerErrors.has('content') ? 'is-invalid form-control' : 'form-control'" v-model="form.content" @input="cancelFunction('cancelButton1')"></textarea>
                                                        <b-form-invalid-feedback :state="!bannerErrors.has('content')">
                                                        {{ bannerErrors.get("content") }}</b-form-invalid-feedback>
                                                    </div>
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" disabled id="cancelButton1" @click="cancelForm(1)" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                        <button type="button" @click="saveData(1, 1)" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4">Save</button>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab title="Image">
                                            <b-card-text>
                                               <div class="row text-left">
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" v-b-modal.modal-previewcenterdbimage class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Preview</button>
                                                    </div>
                                                    <div class="col-md-5 mt-4 imagesec pl-0">
                                                        <div class="rectimage">
                                                            <img :src="imageUrl"  style="width: 100%;"/>
                                                        <div class="atcmntbtn" :class="imageErrors.has('image') ? 'is-invalid' : ''">
                                                            <input type="file" @mousedown="imageErrors.clear('image')"  @change="fileChange(6)" ref="fileImage" name="avatar" accept="image/png, image/jpeg">
                                                        </div>
                                                          <b-form-invalid-feedback class="mt-6"  :state="!imageErrors.has('image')">
                                                            {{ imageErrors.get("image") }}</b-form-invalid-feedback>
                                                        </div>
                                                        <p class="mt-2 text-right notetext">Please upload 800 X 450 image</p>
                                                    </div>
                                                    <div class="col-md-7">
                                                    </div>
                                                    <div class="col-md-1 px-0">
                                                        <label>Text</label><span v-b-tooltip.hover title="Maximum 200 characters">
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                    </div>
                                                    <div class="col-md-11">
                                                        <textarea rows="3" :class="imageErrors.has('content') ? 'is-invalid form-control' : 'form-control'" v-model="imageForm.content" @keydown="imageErrors.clear('content')" @input="cancelFunction('cancelButton2')"></textarea>
                                                        <b-form-invalid-feedback :state="!imageErrors.has('content')">
                                                        {{ imageErrors.get("content") }}</b-form-invalid-feedback>
                                                    </div>
                                                 <div class="col-md-1">
                                                   <label>Link</label>
                                                   <span v-b-tooltip.hover>
                                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                                      </span>
                                                 </div>
                                                 <div class="col-md-11 mt-4">
                                                   <input :class="imageErrors.has('link') ? 'is-invalid form-control' : 'form-control'" v-model="imageForm.link"  @keydown="imageErrors.clear('link')">
                                                   <b-form-invalid-feedback :state="!imageErrors.has('link')">
                                                     {{ imageErrors.get("link") }}</b-form-invalid-feedback>
                                                 </div>
                                                    <div class="col-md-12 text-right mt-4">
                                                        <button type="button" disabled id="cancelButton2" @click="cancelForm(6)" class="btn btn-secondary mr-3 px-3">Cancel</button>
                                                        <button type="button" @click="saveData(1, 6)" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-4">Save</button>
                                                    </div>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab title="Partner" @click="partnerClick()">
                                            <b-card-text>
                                              <div class="row text-left" v-if="partnerList==true">
                                                <div class="col-md-12 text-right mt-4">
                                                  <button type="button" @click="addPartners()" class="btn btn darkblubtn btn-outline-primary btn-outline-primary px-3">Add Partner</button>
                                                </div>
                                                <table class="col-md-12" id="partnersList" style="width: 100%; !important;">
                                                  <thead class="thead-dark">
                                                  <tr>
                                                    <th class="d-none">Id</th>
                                                    <th>Name</th>
                                                    <th>Logo</th>
                                                    <th>Actions</th>
                                                  </tr>
                                                  </thead>
                                                  <tbody>
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div class="row text-left" v-if="partnerCreate==true">
                                                <template>
                                                  <section class="content">
                                                    <div class="container-fluid">
                                                      <div class="row">
                                                        <custom-alert v-if="displayAlert==true" id="alert_box" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
                                                        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
                                                          <div class="card">
                                                            <div class="card-body table-responsive">
                                                              <div class="row bor-bot">
                                                                <div class="col-md-8 pl-0">
                                                                  <h1 class="m-0 text-dark pt-2 pb-3 text-left">{{partner.title}}</h1>
                                                                </div>
                                                              </div>
                                                              <div class="emiltempform">
                                                                <form ref="form" @submit.stop.prevent="addPartner">
                                                                  <div class="row">
                                                                    <div class="col-md-2 mt-3 text-right">
                                                                      <label>Name</label><sup class="text-danger">*</sup>
                                                                    </div>
                                                                    <div class="col-md-3 mt-3">
                                                                      <input :class="partnerErrors.has('name') ? 'is-invalid form-control' : 'form-control'" v-model="partner.name" @keydown="partnerErrors.clear('name')"/>

                                                                      <b-form-invalid-feedback :state="!partnerErrors.has('name')">
                                                                        {{ partnerErrors.get("name") }}</b-form-invalid-feedback>
                                                                    </div>
                                                                    <div class="col-md-7 mt-3">
                                                                      </div>
                                                                    <div class="col-md-2 mt-3 text-right">
                                                                      <label>Content</label><sup class="text-danger">*</sup>
                                                                    </div>
                                                                    <div class="col-md-10 mt-3">
                                                                      <ckeditor :config="{ extraPlugins:'colorbutton' }" :class="partnerErrors.has('description') ? 'is-invalid' : ''" v-model="partner.description"></ckeditor>
                                                                      <!-- <vue-editor v-model="content"></vue-editor> -->
                                                                      <b-form-invalid-feedback :state="!partnerErrors.has('description')">
                                                                        {{ partnerErrors.get("description") }}</b-form-invalid-feedback>
                                                                    </div>
                                                                    <div class="col-md-2 mt-3 text-right">
                                                                      <label>Logo</label>
                                                        <i class="fa fa-info-circle" aria-hidden="true" id="logoTooltip"></i>
                                                      <sup class="text-danger">*</sup>
                                                                    </div>

                                                                    <div class="col-md-3 mt-4 imagesec pl-0">
                                                                      <div class="rectimage">
                                                                        <img :src="logoUrl" style="width: 100%;"/>
                                                                        <div class="atcmntbtn" :class="partnerErrors.has('logo') ? 'is-invalid' : ''">
                                                                          <input accept=".jpg, .jpeg, .png" type="file" @mousedown="partnerErrors.clear('logo')" name="avatar" @change="fileChange(3)" ref="filePartner">
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div class="row col-md-12">
                                                                      <div class="col-md-2"></div>
                                                                      <div class="col-md-4">
                                                                    <b-form-invalid-feedback :state="!partnerErrors.has('logo')">
                                                                      {{ partnerErrors.get("logo") }}</b-form-invalid-feedback>
                                                                      </div>
                                                                    </div>

                                                                    <div class="col-md-12 text-right mt-3">
                                                                      <button type="button" class="btn btn-secondary mr-3" @click="cancelButton()">Cancel</button>
                                                                      <button type="button" v-if="partner.title=='Create Partner'" @click="handleSubmit()" class="btn btn-primary mr-3 btnred">Save</button>
                                                                      <button type="button" v-if="partner.title=='Edit Partner'" @click="editSubmit()" class="btn btn-primary mr-3 btnred">Update</button>

                                                                    </div>
                                                                  </div>
                                                                  <b-tooltip target="logoTooltip"  placement="bottom">
                                                                    <p class="my-0 py-0">Support Files : jpg/jpeg/png</p>
                                                                    <p class="my-0 py-0">Please upload 150 X 150 image</p>
                                                                  </b-tooltip>
                                                                </form>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </template>
                                              </div>
                                              <b-modal size="lg" id="modal-1" ref="content-modal" title="Partner Content" :modal-class="textareamodal">
                                                <p class="my-4" v-html="partnerContent"></p>
                                              </b-modal>
                                            </b-card-text>
                                          </b-tab>
                                        </b-tabs>
                                    </b-card>
                                    </div> 
                               </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <b-modal id="modal-previewcenter" size="xl" title="" hide-footer="true" :modal-class="mobileclass">
            <b-tabs content-class="mt-3">
                <b-tab title="Web View" active>
                    <div class="row">
                        <div class="col-md-12 webview">
                            <div class="rectimage">
                              <img :src="bannerUrl"/>
                              <div class="imgovlycont">
                                <h3>{{form.title}}</h3>
                                <p><span>{{ form.content }}</span></p>
                              </div>
                            </div> 
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Mobile View">
                    <div class="row">
                        <div class="col-md-12 mobview">
                            <div class="rectimage">
                              <img :src="bannerUrl"/>
                              <div class="imgovlycont">
                                <h3>{{form.title}}</h3>
                                <p><span>{{ form.content }}}</span></p>
                              </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>

        <b-modal id="modal-previewcenterdbimage" size="xl" title="" hide-footer="true" :modal-class="dasboardclass">
            <b-tabs content-class="mt-3">
                <b-tab title="Web View" active>
                    <div class="row">
                        <div class="col-md-6 webview">
                            <div class="dbrectimage">
                              <img :src="imageUrl"/>
                              <div class="imgovlycont">
                                <p><span>{{ imageForm.content }}</span></p>
                              </div>
                            </div> 
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Mobile View">
                    <div class="row">
                        <div class="col-md-12 mobview mobileview">
                            <div class="dbrectimage">
                              <img :src="imageUrl"/>
                              <div class="imgovlycont">
                                <p><span>{{ imageForm.content }}</span></p>
                              </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-modal>
    </section>
</template>

<script>

  import axios from "axios";
  import CustomAlert from "../CustomAlert";
  import Errors from "../../Errors";
  import "datatables.net-responsive-dt/js/responsive.dataTables";
  import "datatables.net-dt/css/jquery.dataTables.min.css";
  import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
  import $ from "jquery";


  export default {
    components:{
      CustomAlert
    },
    data() {
      return {
        dasboardclass:'previewpopupmobilesec dasboardimgsection popupfullheight',
        mobileclass: 'previewpopupmobilesec membrdasboarpopup popupfullheight',
        bannerErrors: new Errors(),
        imageErrors: new Errors(),
        iframe: {
          src: '/'
        },
        bannerUrl:'/img/mdrectangleimage.f47903fa.svg',
        imageUrl:'/img/mdrectangleimage.f47903fa.svg',
        form:{
          title:'',
          content:'',
          image:'',
          section_id:'',
          page_id:''
        },
        resetForm:{
          title:'',
          content:'',
          banner:'/img/mdrectangleimage.f47903fa.svg'
        },
        resetImageForm:{
          content:'',
          banner:'/img/mdrectangleimage.f47903fa.svg'
        },
        imageForm:{
          content:'',
          image:'',
          link:'',
          section_id:'',
          page_id:''
        },
        partnerList:true,
        partnerCreate:false,
        partner:{
          name:'',
          logo:'',
          description:'',
          id:'',
          title:'Create Partner'
        },
        partnerErrors: new Errors(),
        logoUrl:'/img/mdrectangleimage.f47903fa.svg',
        partnerContent:'',
        textareamodal: 'textareacontmodal',
      }
    },
    methods: {
      fileChange(section) {

        if(section == 1){
          const file = this.$refs.fileBanner.files[0];
          this.form.image = file;
          this.bannerUrl = URL.createObjectURL(file);
          $('#cancelButton1').removeAttr("disabled");
        }
        if(section == 6){
          const file = this.$refs.fileImage.files[0];
          this.imageForm.image = file;
          this.imageUrl = URL.createObjectURL(file);
          $('#cancelButton2').removeAttr("disabled");
        }
        if(section == 3){
          const file = this.$refs.filePartner.files[0];
          this.partner.logo = file;
          this.logoUrl = URL.createObjectURL(file);
        }
      },
      getDashboardData()
      {
        let url = this.basePath+'api/cms/page/1';
        axios.get(url, { headers:this.adminHeaders })
            .then((res) => {
              let results = res.data.data;
              for(let k in results){
                 if(results[k].section_id == 1){
                   this.form.title = results[k].title;
                   this.form.content = results[k].content;
                   this.bannerUrl = this.basePath+results[k].image;
                   this.resetForm.banner = this.basePath+results[k].image;
                   this.resetForm.content = results[k].content;
                   this.resetForm.title = results[k].title;
                   this.form.banner_img = results[k].image;
                 }
                if(results[k].section_id == 6){
                  this.imageForm.content = results[k].content;
                  this.imageForm.link = results[k].link;
                  this.imageUrl = this.basePath+results[k].image;
                  this.resetImageForm.banner = this.basePath+results[k].image;
                  this.resetImageForm.content = results[k].content;
                  this.imageForm.banner_img = results[k].image;
                }
              }


            })
            .catch((error) => {
              console.log('Error:'+ error);
            }).finally(() => {
        });
      },
      saveData(pageId, sectionId)
      {
        this.showLoader = true;
        let formData = new FormData();
        if(sectionId == 1){
          this.form.section_id = sectionId;
          this.form.page_id = pageId;
          Object.entries(this.form).forEach(([key, value]) => {
            formData.append(key, value);
          });
        }
        if(sectionId == 6){
          this.imageForm.section_id = sectionId;
          this.imageForm.page_id = pageId;
          Object.entries(this.imageForm).forEach(([key, value]) => {
            formData.append(key, value);
          });
        }
        this.$set(this.adminHeaders, 'Content-Type', 'multipart/form-data');
        axios.post(this.basePath+'api/cms/section', formData, {headers: this.adminHeaders})
            .then((response) => {
              this.showLoader = false;
              if (response.data.status == 'success') {
                if(sectionId == 1){
                  this.resetForm.banner = this.bannerUrl;
                  this.resetForm.content = this.form.content;
                  this.resetForm.title = this.form.title;
                  $('#cancelButton1').attr('disabled', true);
                }
                if(sectionId == 6){
                  this.resetForm.banner = this.imageUrl;
                  this.resetForm.content = this.imageForm.content;
                  $('#cancelButton2').attr('disabled', true);
                }
                //alert("Section has been " + message + ".");
                this.displayAlert = true;
                this.alertMessage = response.data.message;
              }
            }).catch((error) => {
          this.showLoader = false;
          this.onFail(error.response.data.errors, sectionId);
        });
      },
      cancelForm(section)
      {
        if(section == 1){
          this.form.title = this.resetForm.title;
          this.form.content = this.resetForm.content;
          this.bannerUrl = this.resetForm.banner;
          $('#cancelButton1').attr('disabled', true);
        }
        if(section == 6){
          this.imageForm.title = this.resetImageForm.title;
          this.imageForm.content = this.resetImageForm.content;
          this.imageUrl = this.resetImageForm.banner;
          $('#cancelButton2').attr('disabled', true);
        }
      },
      onFail(errors,sectionId) {
        if(sectionId == 1)
        {
          this.bannerErrors.record(errors);
        }
        if(sectionId == 6)
        {
          this.imageErrors.record(errors);
        }
        if(sectionId == 3)
        {
          this.partnerErrors.record(errors);
        }
        //this.errors.record(errors);
      },
      cancelFunction(id){
        $('#'+id).removeAttr("disabled")
      },
      partnerTable(){
        this.$set(this.adminHeaders, 'Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        var self = this;
        this.table = $("#partnersList").DataTable({
          sDom: "lfrtip",
          processing: true,
          serverSide: true,
          pageLength: 10,
          "bFilter": true,
          "bDestroy": true,
          "bSearchable":true,
          ajax: {
            url: this.basePath+ "api/partners/partnersList",
            type: 'post',
            headers:this.adminHeaders,
            data: function (d) {
              d.basePath = self.basePath;
            }
          },
          columns: [
            {
              data: 'id', name: 'id', searchable:false, visible: false, render: (data) => {
                return data || "N/A";
              }
            },
            {
              data: 'name', name: 'name', render: (data) => {
                return data || "N/A";
              }
            },
            {
              data: "logo", name: "logo", orderable: false, searchable: false,render: (data) => {
                return '<img src="'+data+'" alt="partner" height="50" width="50">';
              }
            },
            { data: null , orderable: false, searchable: false, render: (data) => {
                let btn = '';
                btn += `<a class="action-editing viewAnswer" href="javascript:void(0)" title="View Content" data-content="${encodeURIComponent(data.description)}"  ><i class="fas fa-eye"></i></a> `
                btn += `<a class="action-editing edit" data-id="${data.id}" href="javascript:void(0)" title="Edit" ><i class="fas fa-edit"></i></a> `
                  btn += `<a class="action-delete faq_trash delete" data-id="${data.id}" href="javascript:void(0)" title="Delete"><i class="fas fa-trash"></i></a>`
                return btn
              }
            }
          ],
          order: [[0, "desc"]]
        });

        $(document).on("click", "table#partnersList tbody .edit" , function(e){
          self.showLoader = true;
          axios.get(self.basePath + 'api/partners/show/'+$(this).data('id')
              , {
                headers:self.adminHeaders
              }).then(response => {
            if (response.data.status == 'success') {
              self.partner.title = 'Edit Partner';
              self.partner.name = response.data.data.name;
              self.partner.logo = response.data.data.logo;
              self.partner.description = response.data.data.description;
              self.partner.id = response.data.data.id;
              self.logoUrl = self.basePath+'images/partners/'+response.data.data.logo;
              self.showLoader = false;
              self.partnerCreate = true;
              $('#partnersList_wrapper').addClass('d-none');
            } else {
              console.log(response);
            }
          }).catch(error => {
            self.showLoader = false;
            self.errors = error.response.data.errors || {};
          });
        }).on("click", "table#partnersList tbody .delete" , function(e){
              self.$bvModal.msgBoxConfirm("Are you sure about this ?" ,{
                title: 'Delete',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Delete',
                cancelTitle: 'Cancel',
                footerClass: 'p-2',
                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: false
              }).then(value => {
                if (String(value) == "true") {
                e.preventDefault();
                self.showLoader = true;
                axios.delete(self.basePath + 'api/partners/delete/' + $(this).data('id')
                    , {
                      headers: self.adminHeaders
                    }).then(response => {
                  if (response.data.status == 'success') {
                    self.showLoader = false;
                    self.table.ajax.reload();
                    self.displayAlert = true;
                    self.alertMessage = "Successfully Deleted ";
                  } else {
                    console.log(response);
                  }
                }).catch(error => {
                  self.showLoader = false;
                  self.errors = error.response.data.errors || {};
                });
              }});
        }).on("click", "table#partnersList tbody .viewAnswer" , function(e) {
            var content = e.target.parentNode.getAttribute('data-content');
            self.partnerContent = decodeURIComponent(content);
          self.$refs['content-modal'].show()
        });
      },
      addPartners(){
        this.partner.title = 'Create Partner';
        this.partnerCreate = true;
        this.clearPartnerData();
        $('#partnersList_wrapper').addClass('d-none');
        this.logoUrl = '/img/mdrectangleimage.f47903fa.svg';
      },
      handleSubmit() {
        this.showLoader = true;
        let formData = new FormData();
        formData.append('logo', this.partner.logo);
        formData.append('name', this.partner.name);
        formData.append('description', this.partner.description);
        axios.post(this.basePath + 'api/partners/store', formData
            , {
              headers:this.adminHeaders
            }).then(response => {
          if (response.data.status == 'success') {
            this.partnerList = true;
            this.partnerCreate = false;
            $('#partnersList_wrapper').removeClass('d-none');
            this.table.ajax.reload();
            this.showLoader = false;
            this.displayAlert = true;
            this.alertMessage = "Successfully Created";
            this.clearPartnerData();
          } else {
            console.log(response);
          }
        }).catch(error => {
          this.onFail(error.response.data.errors, 3);
          this.showLoader = false;
        });

      },
      cancelButton(){
        this.partnerErrors=  new Errors();
        this.partnerList = true;
        this.partnerCreate = false;
        $('#partnersList_wrapper').removeClass('d-none');
        this.table.ajax.reload();
        this.clearPartnerData();
      },
      clearPartnerData(){
        this.partner.name='';
        this.partner.description='';
        this.partner.logo='';
        this.logoUrl='';
        this.partner.id='';
      },
      editSubmit() {
        this.showLoader = true;
        let formData = new FormData();
        formData.append('logo', this.partner.logo);
        formData.append('name', this.partner.name);
        formData.append('description', this.partner.description);
        formData.append('id', this.partner.id);
        axios.post(this.basePath + 'api/partners/update/'+this.partner.id, formData
            , {
              headers:this.adminHeaders
            }).then(response => {
          if (response.data.status == 'success') {
            this.partnerList = true;
            this.partnerCreate = false;
            $('#partnersList_wrapper').removeClass('d-none');
            this.table.draw();
            this.showLoader = false;
            this.displayAlert = true;
            this.alertMessage = "Successfully Updated";
            this.clearPartnerData();
          } else {
            console.log(response);
          }
        }).catch(error => {
          this.onFail(error.response.data.errors, 3);
          this.showLoader = false;
        });

      },
      partnerClick(){
        this.partnerList = true;
        this.partnerCreate = false;
        $('#partnersList_wrapper').removeClass('d-none');
        this.table.ajax.reload();
      }
    },
    mounted() {
        this.getDashboardData();
        this.partnerTable();
    }
  }
</script>